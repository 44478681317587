<template>
  <div class="modal" :class="{'is-active': opened}">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Save View</p>
        <button class="delete" @click="close"></button>
      </header>
      <section class="modal-card-body">

        <div class="field" v-if="!view">
          <label class="label">Save to</label>
          <div class="control">
            <div class="select">
              <select v-model="newViewId">
                <option>New</option>
                <option v-for="(v, i) in views" :key="'view-option-' + i" :value="v.id">
                  {{v.name || v.id}} 
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label">Name</label>
          <div class="control">
            <input class="input" v-model="newName" :class="{'is-danger': !newNameValid}" type="text" placeholder="View name">
          </div>
          <p class="help is-info">Name can only contain alpha-numeric characters and underscores (A-z, 0-9, and _ )</p>
        </div>

        <div v-if="error" class="notification is-danger is-light">
          <button class="delete" @click="error=''"></button>
          {{error}}
        </div>
      </section>
      <footer class="modal-card-foot">
        <a class="button is-link" :class="{'is-loading': waiting}" :disabled="!newNameValid" @click="save">Save</a>
        <a class="button" @click="close">Cancel</a>
      </footer>
    </div>
  </div>
</template>
  
<script>

export default {
  name: 'save-view-modal',
  props: ['opened', 'views', 'view', 'selection'],
  data () {
    return {
      error: '',
      waiting: false,
      newViewId: 'New',
      newName: '',
    }
  },
  computed: {
    server () {
      return this.$store.state.config.server
    },
    newNameValid () {
      return /^[a-zA-Z0-9_]+$/.test(this.newName)
    },
  },
  watch: {
    newViewId: function (val) {
      if (val == 'New') {
        this.newName = ''
      } else {
        for (var view of this.views) {
          if (view.id == val) {
            this.newName = view.name || ''
          }
        }
      }
    },
    opened: function (val) {
      if (val) {
        if (this.view) {
          this.newViewId = this.view.id
          this.newName = this.view.name || ''
        } else {
          this.newViewId = 'New'
          this.newName = ''
        }
      }
      
    }
  },
  methods: {
    close () {
      this.$emit('closeSaveViewModal', false)
    },
    save () {
      if (!this.newNameValid) {
        return
      }
      var message = {
        viewName: this.newName,
        itemIds: this.view ? this.view.returnedItemIds : this.selection.join(',')
      }
      if (this.newViewId != 'New') {
        message.viewId = this.newViewId
      }
      this.$http.post(this.server + '/myapp/save-returned-items-view/', message).then(resp => {
        this.$emit('closeSaveViewModal', true)
      }, err => {
        this.error = err.body
      })
    },
  },
}
</script>

<style scoped>

</style>