<template>
  <div class="m-5 px-2">
    <article class="message is-danger" v-if="!token">
      <div class="message-body">
        You have not signed in yet. Please go back to <a href="/">home page</a> to sign in first.
      </div>
    </article>
    <div v-if="token">
      <div>
        <h1 class="title">Returns</h1>
      </div>
      <div class="mt-5">
        <div v-if="waiting">
          <span class="icon is-medium is-size-4">
            <i class="fas fa-spinner fa-pulse"></i>
          </span>
        </div>
        <div v-else>

          <div class="tabs is-boxed">
            <ul>
              <li :class="{'is-active': !showViews}">
                <a @click="showViews=false">All Returns</a>
              </li>
              <li :class="{'is-active': showViews}">
                <a @click="showViews=true">Views</a>
              </li>
            </ul>
          </div>

          <div v-if="!showViews">

            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label">Class</label>
                  <div class="control">
                    <div class="select">
                      <select v-model="filters.productClass.selected">
                        <option v-for="(o, i) in filters.productClass.options" :key="'p-c-o-'+i">{{o}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">Sku</label>
                  <div class="control">
                    <div class="select">
                      <select v-model="filters.itemSku.selected">
                        <option v-for="(o, i) in filters.itemSku.options" :key="'i-s-o-'+i">{{o}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">Received At</label>
                  <div class="control">
                    <div class="select">
                      <select v-model="filters.warehouse.selected">
                        <option v-for="(o, i) in filters.warehouse.options" :key="'w-h-o-'+i">{{o}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">Returned Date</label>
                  <div class="control">
                    <div class="select is-multiple">
                      <select multiple :size="filters.returnedDate.options.length" v-model="filters.returnedDate.selected">
                        <option v-for="(o, i) in filters.returnedDate.options" :key="'r-d-o-'+i">{{o}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">Shipped from</label>
                  <div class="control">
                    <div class="select">
                      <select v-model="filters.shippedFrom.selected">
                        <option v-for="(o, i) in filters.shippedFrom.options" :key="'s-f-o-'+i">{{o}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">Condition</label>
                  <div class="control">
                    <div class="select">
                      <select v-model="filters.itemCondition.selected">
                        <option v-for="(o, i) in filters.itemCondition.options" :key="'c-o-'+i">{{o}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <button class="button is-link" :disabled="!selection.length" @click="openSaveViewModal()">
                Save selected {{selection.length}} item(s) to view
              </button>
            </div>

            <div>
              <div class="has-text-weight-bold">Loaded {{offset}} / {{count}}</div>
              <div>
                <button class="button" v-if="offset < count" @click="queryReturnedItems">Load more</button>
              </div>
            </div>

            <table class="table is-fullwidth is-hoverable is-striped mt-4">
              <thead>
                <tr>
                  <th><label class="checkbox"><input v-model="allSelected" type="checkbox"></label></th>
                  <th class="is-clickable" @click="changeSortOption('id')">
                    <span>Id</span>
                    <span class="icon" v-if="sortOption.field == 'id'">
                      <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                    </span>
                  </th>
                  <th class="is-clickable" @click="changeSortOption('poNum')">
                    <span>PO#</span>
                    <span class="icon" v-if="sortOption.field == 'poNum'">
                      <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                    </span>
                  </th>
                  <th class="is-clickable" @click="changeSortOption('itemSku')">
                    <span>Sku</span>
                    <span class="icon" v-if="sortOption.field == 'itemSku'">
                      <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                    </span>
                  </th>
                  <th class="is-clickable" @click="changeSortOption('serialNum')">
                    <span>Serial#</span>
                    <span class="icon" v-if="sortOption.field == 'serialNum'">
                      <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                    </span>
                  </th>
                  <th class="is-clickable" @click="changeSortOption('productClass')">
                    <span>Class</span>
                    <span class="icon" v-if="sortOption.field == 'productClass'">
                      <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                    </span>
                  </th>
                  <th class="is-clickable" @click="changeSortOption('itemCondition')">
                    <span>Condition</span>
                    <span class="icon" v-if="sortOption.field == 'itemCondition'">
                      <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                    </span>
                  </th>
                  <th class="is-clickable" @click="changeSortOption('shippedFrom')">
                    <span>Shipped from</span>
                    <span class="icon" v-if="sortOption.field == 'shippedFrom'">
                      <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                    </span>
                  </th>
                  <th class="is-clickable" @click="changeSortOption('warehouse')">
                    <span>Received at</span>
                    <span class="icon" v-if="sortOption.field == 'warehouse'">
                      <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                    </span>
                  </th>
                  <th class="is-clickable" @click="changeSortOption('createdAt')">
                    <span>Returned at</span>
                    <span class="icon" v-if="sortOption.field == 'createdAt'">
                      <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                    </span>
                  </th>
                  <th>Picture</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in showingItems">
                  <td><label class="checkbox" @click="checkBoxClicked(item.id, $event)"><input v-model="selectionMap[item.id]" type="checkbox"></label></td>
                  <td>{{item.id}}</td>
                  <td>{{item.poNum}}</td>
                  <td>{{item.itemSku}}</td>
                  <td>{{item.serialNum}}</td>
                  <td>{{item.productClass}}</td>
                  <td>{{item.itemCondition}}</td>
                  <td>{{item.shippedFrom}}</td>
                  <td>{{item.warehouse}}</td>
                  <td>{{item.createdAtLabel}}</td>
                  <td>
                    <div v-if="imageMap[item.id] && imageMap[item.id].attachmentId">
                      <div v-if="imageMap[item.id].loading">
                        <span class="icon is-medium is-size-4">
                          <i class="fas fa-spinner fa-pulse"></i>
                        </span>
                      </div>
                      <div v-else>
                        <div v-if="imageMap[item.id].url">
                          <figure class="image is-clickable" @click="openImageModal(imageMap[item.id].url)">
                            <img style="max-height:100px;max-width:100px;height:auto;width:auto;" :src="imageMap[item.id].url" />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                
              </tbody>
            </table>

            <div>
              <div class="has-text-weight-bold">Loaded {{offset}} / {{count}}</div>
              <div>
                <button class="button" v-if="offset < count" @click="queryReturnedItems">Load more</button>
              </div>
            </div>
          </div>

          <div v-if="showViews">
            <div v-if="!views.length">
              <article class="message is-info">
                <div class="message-body">
                  No views yet.
                </div>
              </article>
            </div>
            <div v-else="views.length">
              <table class="table is-fullwidth is-hoverable is-striped mt-4">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Link</th>
                    <th>Size</th>
                    <th>Update At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v, i) in views" :key="'view-table-'+i">
                    <td>{{v.id}}</td>
                    <td>{{v.name}}</td>
                    <td>
                      <a target="_blank" :href="v.link">{{v.link}}</a>
                    </td>
                    <td>{{v.size}}</td>
                    <td>{{v.updatedAtLabel}}</td>
                    <td>
                      <a class="icon has-text-info" @click="openSaveViewModal(v)">
                        <i class="fas fa-edit"></i>
                      </a>
                      <a class="icon has-text-danger" @click="deleteView(v)">
                        <i class="fas fa-trash"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
            
        </div>

          
      </div>
      <div v-if="error" class="notification is-danger is-light">
        <button class="delete" @click="error=''"></button>
        {{error}}
      </div>
      <div class="modal" :class="{'is-active': imageModal.opened}">
        <div class="modal-background"></div>
        <div class="modal-content">
          <p class="image">
            <img :src="imageModal.url" alt="">
          </p>
        </div>
        <button class="modal-close is-large" aria-label="close" @click="imageModal.opened = false"></button>
      </div>
    </div>
    <save-view-modal :opened="saveViewModal.opened" :views="views" :selection="selection" :view="saveViewModal.view"
      @closeSaveViewModal="closeSaveViewModal">
    </save-view-modal>
  </div>
</template>

<script>
import dateFormat from 'dateformat'
import SaveViewModal from '@/components/modals/SaveViewModal'

export default {
  name: 'ReturnedItems',
  components: {
    SaveViewModal
  },
  data () {
    return {
      error: '',
      waiting: false,
      items: [],
      count: null,
      offset: 0,
      imageMap: {},
      imageModal: {
        opened: false,
        url: ''
      },
      sortOption: {
        field: 'createdAt',
        asc: false
      },
      selectionMap: {},
      allSelected: false,
      lastClicked: null,
      showViews: false,
      views: [],
      saveViewModal: {
        opened: false,
        view: null,
      },
      filters: {
        ready: false,
        productClass: {
          options: ['All'],
          selected: 'All'
        },
        itemSku: {
          options: ['All'],
          selected: 'All'
        },
        warehouse: {
          options: ['All'],
          selected: 'All'
        },
        shippedFrom: {
          options: ['All'],
          selected: 'All'
        },
        itemCondition: {
          options: ['All'],
          selected: 'All'
        },
        returnedDate: {
          options: ['All'],
          selected: ['All'],
        },
      },
    }
  },
  computed: {
    server () {
      return this.$store.state.config.server
    },
    token () {
      return this.$store.state.user.token
    },
    filtersValue () {
      var filters = {}
      if (this.filters.productClass.selected != 'All') {
        filters.productClass = this.filters.productClass.selected
      }
      if (this.filters.itemSku.selected != 'All') {
        filters.itemSkuName = this.filters.itemSku.selected
      }
      if (this.filters.warehouse.selected != 'All') {
        filters.warehouse = this.filters.warehouse.selected
      }
      if (this.filters.shippedFrom.selected != 'All') {
        filters.shippedFrom = this.filters.shippedFrom.selected
      }
      if (this.filters.itemCondition.selected != 'All') {
        filters.itemCondition = this.filters.itemCondition.selected
      }
      if (!this.filters.returnedDate.selected.includes('All')) {
        filters.dates = this.filters.returnedDate.selected
      }
      return filters
    },
    showingItems () {
      var vm = this
      var sortedItems = this.items.sort((a, b) => {
        var va = a[vm.sortOption.field]
        var vb = b[vm.sortOption.field]
        if (vm.sortOption.field == 'id' || vm.sortOption.field == 'createdAt') {
          return vm.sortOption.asc ? va - vb : vb - va
        }
        return vm.sortOption.asc ? va.localeCompare(vb) : vb.localeCompare(va)
      })
      return sortedItems.map(item => {
        var i = JSON.parse(JSON.stringify(item))
        i.createdAtLabel = dateFormat(new Date(i.createdAt * 1000), 'yyyy-mm-dd')
        return i
      })
    },
    selection () {
      var selection = []
      for (var k in this.selectionMap) {
        if (this.selectionMap[k]) {
          selection.push(k)
        }
      }
      return selection
    },
  },
  watch: {
    allSelected: function (val) {
      for (var item of this.showingItems) {
        this.selectionMap[item.id] = val
      }
    },
    filters: {
      handler (newVal, oldVal) {
        if (oldVal.ready) {
          this.offset = 0
          this.count = null
          this.selectionMap = {}
          this.items = []
          this.$nextTick(() => {
            this.queryReturnedItems()
          })
        }
      },
      deep: true
    }
  },
  methods: {
    getFilterOptions () {
      this.$http.get(this.server + '/myapp/get-second-config/').then(resp => {
        var options = resp.body
        this.filters = {
          ready: true,
          productClass: {
            options: ['All'].concat(options.productClasses),
            selected: 'All'
          },
          itemSku: {
            options: ['All'].concat(options.itemSkuNames),
            selected: 'All'
          },
          warehouse: {
            options: ['All'].concat(options.warehouses),
            selected: 'All'
          },
          shippedFrom: {
            options: ['All'].concat(options.shippedFroms),
            selected: 'All'
          },
          itemCondition: {
            options: ['All'].concat(options.itemConditions),
            selected: 'All'
          },
          returnedDate: {
            options: ['All'].concat(options.dates),
            selected: ['All'],
          },
        }
      }, err => {
        this.error = err.body
      })
    },
    queryReturnedItems () {
      var data = {
        offset: this.offset,
        filters: this.filtersValue
      }
      this.waiting = true
      this.$http.post(this.server + '/myapp/query-returned-items/', data).then(resp => {
        this.count = resp.body.count
        this.items = this.items.concat(resp.body.items)
        this.offset += resp.body.items.length

        var selectionMap = JSON.parse(JSON.stringify(this.selectionMap))
        for (var item of resp.body.items) {
          selectionMap[item.id] = false
        }
        this.selectionMap = selectionMap

        this.getImageMap(resp.body.items)
        this.waiting = false
      }, err => {
        this.error = err.body
        this.waiting = false
      })
    },
    getReturnedItems () {
      this.waiting = true
      this.$http.get(this.server + '/myapp/get-returned-items-with-offset/' + this.offset).then(resp => {
        if (this.count == null) {
          this.count = resp.body.count
        } else if (this.count != resp.body.count) {
          this.error = 'Please refresh page to get the latest data!'
        }

        this.items = this.items.concat(resp.body.items)


        this.sortOption = {
          field: 'createdAt',
          asc: false
        }
        this.offset += resp.body.items.length

        var selectionMap = JSON.parse(JSON.stringify(this.selectionMap))
        for (var item of resp.body.items) {
          selectionMap[item.id] = false
        }
        this.selectionMap = selectionMap

        this.getImageMap(resp.body.items)
        this.waiting = false
      }, err => {
        this.error = err.body
        this.waiting = false
      })
    },
    getImageMap (items) {
      var imageMap = JSON.parse(JSON.stringify(this.imageMap))
      var newItems = []
      for (var item of items) {
        if (!imageMap[item.id]) {
          var attachments = item.attachments.split(',')
          var attachmentId = attachments[2]
          imageMap[item.id] = {itemId: item.id, attachmentId: attachmentId, url: '', loading: Boolean(attachmentId)}
          newItems.push(item)
        }
      }
      this.imageMap = imageMap

      for (var item of newItems) {
        var imageObj = imageMap[item.id]
        if (!imageObj.attachmentId || imageObj.url) {
          continue
        }
        var message = {
          returnedItemId: item.id,
          attachmentId: imageObj.attachmentId,
        }
        this.$http.post(this.server + '/myapp/returns-page-get-attachment-url/', message).then(resp => {
          this.imageMap[resp.body.returnedItemId].url = resp.body.url
          this.imageMap[resp.body.returnedItemId].loading = false
        }, err => {
          this.error = err.body
        })
      }
    },
    openImageModal (url) {
      this.imageModal.url = url
      this.imageModal.opened = true
    },
    changeSortOption (field) {
      if (this.sortOption.field == field) {
        this.sortOption.asc = !this.sortOption.asc
      } else {
        this.sortOption.field = field
        this.sortOption.asc = true
      }
    },
    checkBoxClicked (itemId, event) {        
      if (this.lastClicked) {
        if (event.shiftKey) {
          var lastIndex = this.findItemIndex(this.lastClicked)
          var thisIndex = this.findItemIndex(itemId)
          if (lastIndex < thisIndex) {
            for (var i=lastIndex;i<thisIndex;i++) {
              this.selectionMap[this.showingItems[i].id] = this.selectionMap[this.lastClicked]
            }
          }
          if (lastIndex > thisIndex) {
            for (var i=lastIndex;i>thisIndex;i--) {
              this.selectionMap[this.showingItems[i].id] = this.selectionMap[this.lastClicked]
            }
          }
        }
        this.lastClicked = itemId
      } else {
        this.lastClicked = itemId
      }
    },
    findItemIndex (itemId) {
      for (var i=0;i<this.showingItems.length;i++) {
        if (this.showingItems[i].id == itemId) {
          return i
        }
      }
    },
    getReturnedItemsViews () {
      this.$http.get(this.server + '/myapp/get-returned-items-views/').then(resp => {
        this.views = resp.body.map(v => {
          var i = JSON.parse(JSON.stringify(v))
          i.updatedAtLabel = dateFormat(new Date(i.updatedAt * 1000), 'yyyy-dd-mm HH:MM:ss')
          i.link = window.location.origin + '/returns/view/' + i.id + '/' + i.code + '/' + i.name
          i.size = i.returnedItemIds.split(',').length
          return i
        })
      }, err => {
        this.error = err.body
      })
    },
    saveToView (view) {
      var message = { itemIds: this.selection.join(',') }
      if (view) {
        message.viewId = view.id
      }
      this.$http.post(this.server + '/myapp/save-returned-items-view/', message).then(resp => {
        this.getReturnedItemsViews()
        this.showViews = true
      }, err => {
        this.error = err.body
      })
    },
    openSaveViewModal (view) {
      if (view) {
        this.saveViewModal.view = view
      }
      this.saveViewModal.opened = true
    },
    closeSaveViewModal (saved) {
      this.saveViewModal.opened = false
      this.saveViewModal.view = null
      if (saved) {
        this.getReturnedItemsViews()
        this.showViews = true
      }
    },
    deleteView (view) {
      var confirm = {
        title: 'Delete View',
        message: 'Are you sure to delete this view?',
        button: 'Yes, I am sure.',
        callback: {
          context: this,
          method: this.deleteViewConfirmed,
          args: [view]
        }
      }
      this.$store.commit('modals/openConfirmModal', confirm)
    },
    deleteViewConfirmed (view) {
      var message = {viewId: view.id}
      this.$http.post(this.server + '/myapp/delete-returned-items-view/', message).then(resp => {
        this.getReturnedItemsViews()
      }, err => {
        this.error = err.body
      })
    },
    makeSku (value) {
      return value.split('(')[0].trim()
    },
    makeReturnedDate (value) {
      return dateFormat(new Date(value * 1000), 'yyyy-mm')
    },
  },
  mounted () {
    this.$nextTick(function () {
      this.getFilterOptions()
      this.queryReturnedItems()
      this.getReturnedItemsViews()
    })
  },
}
</script>

<style scoped>
.image-in-table {
  max-height:100px;
  max-width:100px;
  height:auto;
  width:auto;
}
</style>
  